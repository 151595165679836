import React, { AnchorHTMLAttributes } from 'react';
import { Container } from './Container';
// @ts-ignore
import logo from '../assets/logo-white.svg';

const TopBarLink: React.FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  ...props
}) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="hover:underline"
      {...props}
    >
      {children}
    </a>
  );
};

export const BottomBar: React.FC = () => {
  return (
    <div className="bg-gray text-night p-8 lg:py-5 lg:px-0">
      <Container className="flex justify-center items-center lg:justify-between">
        <img src={logo} className="hidden h-12 lg:block" />
        <ul className="flex space-x-5">
          <li>
            <TopBarLink href="https://bitmade.de/impressum">
              Impressum
            </TopBarLink>
          </li>
          <li>
            <TopBarLink href="https://bitmade.de/datenschutz">
              Datenschutz
            </TopBarLink>
          </li>
          <li>
            <TopBarLink href="https://bitmade.de/kontakt">Kontakt</TopBarLink>
          </li>
        </ul>
      </Container>
    </div>
  );
};
