import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

export const Container: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={clsx('max-w-5xl mx-auto px-6', className)} {...props}>
      {children}
    </div>
  );
};
